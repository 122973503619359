body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
  font-size: 10px !important;
}
.h1center {
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
h1 {
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
}
h2 {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
}
.body-text-black {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.body-text {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.Sub-h1 {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
}
.Tabs-menu-selected {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.Tabs-menu {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #929292;
  text-transform: uppercase;
}
.Button-text {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
h3 {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
}
.Input-text {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
}

.Tiny-text-pink {
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff007f;
  text-transform: uppercase;
}
.Tiny-text {
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

ul {
  list-style: none; /* Remove default bullets */
}

ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #ff007f; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  padding-right: 0.8rem;
  vertical-align: middle;
}

.numbering {
  counter-reset: item;
  list-style: none;
  padding-left: 10px;
}
.numbering li {
  counter-increment: item;
  position: relative;
}
.numbering li::before {
  position: absolute;
  left: -1.8rem;
  content: counter(item) ".";
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.h2-light {
  font-weight: 300;
}

.fixTopCon {
  background-color: #000000;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 10;
  height: 6.6rem;
  padding: 2.2rem 2.4rem;
}

.closeIcon {
  font-size: 2rem;
}

.iconBox {
  width: 1.2rem;
  height: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 2.7rem;
  right: 2.4rem;
  z-index: 15;
}
